<template>
  <div class="flex flex-col gap-2">
    <div v-if="ideal === null">
      Loading..
    </div>
    <div
      v-else-if="showStart"
      class="flex flex-col gap-4 items-center w-100 mt-6"
    >
      <b-button
        variant="primary"
        size="sm"
        @click="startFromScratch()"
        :disabled="loadingCount > 0"
        class="w-40"
      >
        Start from scratch
      </b-button>

      <b-button
        variant="primary"
        size="sm"
        @click="startFromTemplate()"
        :disabled="loadingCount > 0"
        class="w-40"
      >
        Start from a template
      </b-button>
    </div>
    <div v-else>
      <herb-ideal-editor
        :ideal="ideal"
        :loadingCount="loadingCount"
        :options="options"
        @saveIdeal="saveIdeal"
      />
    </div>

    <herb-ideal-template-modal
      v-if="templateModal.showModal"
      :data="templateModal"
      :herbid="herbid"
      @select="selectTemplate"
    />
  </div>
</template>

<script>
const HerbIdealEditor = () => import('@/components/herb/HerbIdealEditor.vue');
const HerbIdealTemplateModal = () => import('@/components/herb/HerbIdealTemplateModal.vue');

export default {
  name: 'HerbIdeal',
  components: {
    HerbIdealEditor,
    HerbIdealTemplateModal,
  },
  props: {
    herbid: Number,
  },
  computed: {
    operationsData() {
      return this.ideal.operations
        .map((operation, index) => ({
          OperId: operation.OperId,
          Ord: index + 1,
          OperHours: this.toHours(operation.OperHours),
          Descr: operation.Descr,
        }));
    },
  },
  data() {
    return {
      loadingCount: 0,
      ideal: null,
      showStart: false,
      options: {
        OperId: [],
      },
      templateModal: {
        showModal: false,
      },
    };
  },
  methods: {
    fetchOptions() {
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.oper' })
        .send({ method: 'opers' })
        .then((res) => {
          this.options.OperId = res.body;
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchIdeal() {
      this.loadingCount++;
      this.$http
        .get(`/ideals/${this.herbid}`)
        .then((res) => {
          res.body.ideal.operations.map((operation) => {
            operation.OperHours = this.fromHours(operation.OperHours);
            return operation;
          });
          this.ideal = res.body.ideal;
          if (this.ideal.idealid === null || this.ideal.operations.length === 0) {
            this.showStart = true;
          }
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch production process: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    startFromScratch() {
      this.showStart = false;
    },
    startFromTemplate() {
      this.templateModal.showModal = true;
    },
    selectTemplate(idealid) {
      this.loadingCount++;
      this.$http
        .get(`/ideal_operations/${idealid}`)
        .then((res) => {
          res.body.operations.map((operation) => {
            operation.OperHours = this.fromHours(operation.OperHours);
            return operation;
          });
          this.ideal.operations = res.body.operations;
          this.showStart = false;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch template: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fromHours(totalHours) {
      const hoursInDay = 24;
      const hoursInMonth = 30 * hoursInDay;

      const months = Math.floor(totalHours / hoursInMonth);
      const days = Math.floor((totalHours % hoursInMonth) / hoursInDay);
      const hours = totalHours % hoursInDay;

      return { months, days, hours };
    },
    toHours(operHours) {
      const hoursInDay = 24;
      const hoursInMonth = 30 * hoursInDay;

      return (
        (parseInt(operHours.months, 10) * hoursInMonth)
        + (parseInt(operHours.days, 10) * hoursInDay)
        + parseInt(operHours.hours, 10)
      );
    },
    fetchAll() {
      this.fetchOptions();
      this.fetchIdeal();
    },
    saveIdeal(ideal) {
      const data = {
        herbid: ideal.herbid,
        idealid: ideal.idealid,
        operations: this.operationsData,
      };
      this.loadingCount++;
      this.$http
        .post(`/ideals/${this.herbid}`)
        .send({ ideal: data })
        .then(() => {
          this.$emit('updated');
        })
        .catch((err) => {
          this.$toast.error(`Failed to save: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
  created() {
    this.fetchAll();
  },
};
</script>

<style lang="scss" scoped>
</style>
